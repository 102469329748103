var modalDialogWindow = (function () {
  var modalDialog = $('.modal-dialog'),
      modalDialogWindowCloseButton = modalDialog.find('.modal-dialog__close-button'),
      overlay = $('.overlay-background'),
      initStatus = false;

  var init = function (options) {
    if( false === initStatus )
    {
      initStatus = true;

      _addCloseModalDialogEventListener();

      _showModalDialogWindow();
      _showOverlayBackground();

      _setOptions(options);
    }
  };

  var destroy = function () {
    _setDefault();
  };

  var _setOptions = function (options) {
    if( options && 'object' == typeof options )
    {
      modalDialog.find('.modal-dialog__title').html( options.title ? options.title : 'Ваш запрос отправлен');

      modalDialog.find('.modal-dialog__message').html(
          options.message ?
              options.message : 'Наш менеджер свяжется с вами в ближайшее время!'
      );
    }
  };

  var _removeCloseModalDialogEventListener = function () {
    modalDialogWindowCloseButton.off('click', _closeDialogModal);
  };

  var _addCloseModalDialogEventListener = function () {
    modalDialogWindowCloseButton.on('click', _closeDialogModal);
  };

  var _setDefault = function () {
    _removeCloseModalDialogEventListener();

    _hideOverlayBackground();
    _hideModalDialogWindow();

    _setDefaultMessage();

    initStatus = false;
  };

  var _setDefaultMessage = function () {
    modalDialog.find('.modal-dialog__title').html('Ваш запрос отправлен');
    modalDialog.find('.modal-dialog__message').html('Наш менеджер свяжется с вами в ближайшее время!');
  };

  var _closeDialogModal = function () {
    _setDefault();
  };

  var _hideOverlayBackground = function () {
    overlay.css({
      opacity: "0",
      visibility: "hidden"
    });
  };

  var _showOverlayBackground = function () {
    overlay.css({
      opacity: "1",
      visibility: "visible"
    });
  };

  var _hideModalDialogWindow = function () {
    modalDialog.css({
      opacity: "0",
      visibility: "hidden"
    });
  };

  var _showModalDialogWindow = function () {
    modalDialog.css({
      opacity: "1",
      visibility: "visible"
    });
  };

  return {
    init : init,
    destroy : destroy
  }
})();