var modalWindow = (function () {
  var modalWindow = $('.modal'),
      closeButton = modalWindow.find('.modal__close-window'),
      form = modalWindow.find('.modal__form'),
      overlay = $('.overlay-background'),
      initStatus = false,
      yandexCounter;

  var init = function (options) {
    if( false === initStatus )
    {
      initStatus = true;

      _showModalWindow();

      _addFormSubmitEventListener();
      _addCloseModalEventListener();

      _showOverlayBackground();

      _setUserOptions(options);
    }
  };

  var destroy = function () {
    _setDefault();
  };

  var _setUserOptions = function (userOptions) {
    if( userOptions && 'object' == typeof(userOptions) )
    {
      // set type of the form
      modalWindow.find('input[name="type"]').val( userOptions.type ? +userOptions.type : 1 );

      // set title
      modalWindow.find('.modal__title').html( userOptions.title ? userOptions.title : 'Форма обратной связи');

      if( userOptions.additionalText )
      {
        modalWindow
            .find('.modal__title')
            .after('<p class="modal__additional-text">' + userOptions.additionalText + '</p>');
      }

      if( userOptions.additionalFormfields )
      {
        form.find('.modal__confirm-block').before(
            '<div class="modal__additional-block">' +
              userOptions.additionalFormfields +
            '</div>'
        );
      }
      else
      {
        // clear form
        var inputs = form.find('input');

        for(i = 4; i < inputs.length; ++i)
        {
          inputs.eq(i).remove();
        }

        form.find('textarea').remove();
      }

      if( userOptions.data )
      {
        for( item in userOptions.data )
        {
          form.append('<input class="modal__form-input modal_hidden-input" type="hidden" name="' + item + '" value="' + userOptions.data[item] + '">');
        }
      }
    }
  };

  var _sendYandexReachGoalEvent = function () {
    if( yaCounter37018325 )
    {
      yaCounter37018325.reachGoal('LOVEC');
    }
  };

  var _setDefault = function () {
    initStatus = false;

    _closeModalWindow();

    _clearEvents();

    _hideOverlayBackground();

    _setDefaultWindow();
  };

  var _setDefaultWindow = function () {
    modalWindow.find('input[name="type"]').val(1);

    modalWindow.find('.modal__title').html('Форма обратной связи');

    modalWindow.find('.modal__additional-text').remove();

    modalWindow.find('.modal__additional-block').remove();
    
    var inputs = modalWindow.find('input');
    var defaultInputs = ['name', 'phone', 'type', 'confirm'];

    // remove not default inputs
    if( inputs.length > 0 )
    {
      inputs.each(function (index) {
        var $this = $(this),
            name = $this.attr('name');

        if( -1 == defaultInputs.indexOf(name) )
        {
          $this.remove();
        }
      })
    }
  };

  var _clearEvents = function () {
    _removeCloseModalEventListener();
    _removeFormSubmitEventListener();
  };

  var _hideOverlayBackground = function () {
    overlay.css({
      opacity: "0",
      visibility: "hidden"
    });
  };

  var _showOverlayBackground = function () {
    overlay.css({
      opacity: "1",
      visibility: "visible"
    });
  };

  var _removeCloseModalEventListener = function () {
    closeButton.off('click', _setDefault);
  };

  var _addCloseModalEventListener = function () {
    closeButton.on('click', _setDefault);
  };

  var _removeFormSubmitEventListener = function () {
    form.off('submit', _formSubmitEvent);
  };

  var _addFormSubmitEventListener = function () {
    form.on('submit', _formSubmitEvent);
  };

  var _formSubmitEvent = function (e) {
    var $this = $(this),
        serializedData = form.serialize(),
        submitButton = $this.find('button[type="submit"]'),
        ajaxSubmitPromise;

    e.preventDefault();

    submitButton.attr('disabled', 'disabled');

    ajaxSubmitPromise = _submitFormToTheServer(serializedData)
        .then(function (result) {
          console.log('success');

          destroy();

          if(result.status && 'success' == result.status)
          {
            modalDialogWindow.init();

            _sendYandexReachGoalEvent();
          }
          else
          {
            modalDialogWindow.init({
              title : 'Произошла ошибка!',
              message : 'Сообщение не отпралено, убедитесь в равильности заполнения полей.'
            });
          }

          setTimeout(function () {
            submitButton.removeAttr('disabled');
          }, 1000);
        })
        .catch(function (exception) {
          console.log('fail');

          destroy();

          modalDialogWindow.init({
            title : 'Произошла ошибка!',
            message : 'Сообщение не отпралено, убедитесь в равильности заполнения полей.'
          });

          setTimeout(function () {
            submitButton.removeAttr('disabled');
          }, 1000);
        });
  };

  var _submitFormToTheServer = function (formData) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        type: 'POST',
        dataType: 'json',
        url: 'php/contact.php',
        beforeSend: function (x) {
          if (x && x.overrideMimeType) {
            x.overrideMimeType("application/json;charset=UTF-8");
          }
        },
        data: formData})
      .done(function (respond, textStatus, jqXHR)
      {
        resolve(respond);
      })
      .fail(function( jqXHR, textStatus, errorThrown )
      {
        reject(jqXHR);
      });
    });
  };

  var _showModalWindow = function () {
    modalWindow.css({
      opacity: "1",
      visibility: "visible"
    });
  };

  var _closeModalWindow = function () {
    modalWindow.css({
      opacity: "0",
      visibility: "hidden"
    });
  };
  
  return {
    init : init,
    destroy: destroy
  }
})();
