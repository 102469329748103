(function() {
  'use strict';

  // accordion
  $('.most-important__item').on('click', '.most-important__title', function () {
    var $this = $(this),
        accordion  = $this.closest('.most-important__item'),
        duration = 500;

    if( accordion.hasClass('most-important_active') ) {

      accordion.removeClass('most-important_active');
      $this.siblings('.most-important__desc').stop().slideUp(duration);

    } else {

      accordion.siblings().filter('.most-important_active').removeClass('most-important_active').find('.most-important__desc').stop().slideUp(duration);
      $this.siblings('.most-important__desc').stop().slideDown(duration);
      accordion.addClass('most-important_active');

    }
  });

  $('.header__callback-link').on('click', function (e) {
    e.preventDefault();

    modalWindow.init({
      type : 1
    });
  });

  $('.page-top__form').on('submit', formSubmitEvent);

  $('.measure__form').on('submit', formSubmitEvent);

  $('.order-now__button').on('click', function (e) {
    e.preventDefault();

    modalWindow.init({
      type : 4
    });
  });

  $('#dilers').on('click', function (e) {
    e.preventDefault();

    modalWindow.init({
      type : 5,
      title : 'Дилерам',
      additionalText : 'Вы можете стать нашим дилером по пластиковым окнам и алюминиевым конструкциям'
    });
  });

  $('#vacancy').on('click', function (e) {
    e.preventDefault();

    modalWindow.init({
      type : 6,
      title : 'Вакансия'
    });
  });

  $('#policy, .policy-link').on('click', function (e) {
    e.preventDefault();

    moreInfoWindow.init({theme : 'light'});
    moreInfoWindow.load('policy');
  });

  function formSubmitEvent(e) {
    var $this = $(this),
        serializedData = $this.serialize(),
        submitButton = $this.find('button[type="submit"]'),
        ajaxSubmitPromise;

    e.preventDefault();

    submitButton.attr('disabled', 'disabled');

    ajaxSubmitPromise = submitFormToTheServer(serializedData)
        .then(function (result) {
          console.log('success');

          if(result.status && 'success' == result.status)
          {
            modalDialogWindow.init();

            sendYandexReachGoalEvent();
          }
          else
          {
            modalDialogWindow.init({
              title : 'Произошла ошибка!',
              message : 'Сообщение не отпралено, убедитесь в равильности заполнения полей.'
            });
          }

          setTimeout(function () {
            submitButton.removeAttr('disabled');
          }, 1000);
        })
        .catch(function (exception) {
          console.log('fail');

          modalDialogWindow.init({
            title : 'Произошла ошибка!',
            message : 'Сообщение не отпралено, убедитесь в равильности заполнения полей.'
          });

          setTimeout(function () {
            submitButton.removeAttr('disabled');
          }, 1000);
        });
  }

  function sendYandexReachGoalEvent() {
    if( yaCounter37018325 )
    {
      yaCounter37018325.reachGoal('LOVEC');
    }
  };

  function submitFormToTheServer(formData) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        type: 'POST',
        dataType: 'json',
        url: 'php/contact.php',
        beforeSend: function (x) {
          if (x && x.overrideMimeType) {
            x.overrideMimeType("application/json;charset=UTF-8");
          }
        },
        data: formData})
          .done(function (respond, textStatus, jqXHR)
          {
            resolve(respond);
          })
          .fail(function( jqXHR, textStatus, errorThrown )
          {
            reject(jqXHR);
          });
    });
  }
})();